body, html, #root {
  height: 100%
}

html {
  scroll-behavior: smooth;
}

@keyframes animatedPadding {
  0% { padding: 1px }
  50% { padding: 6px }
  0% { padding: 1px }
}
